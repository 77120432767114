@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.barrier) {
    border-bottom: 2px solid $colorArctic;
    margin-top: 20px;
    margin-bottom: 20px;
}

:local(.container) {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
}

:local(.scrollableContent) {
    flex: 1 1;
    overflow-y: auto;
}