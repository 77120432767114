@import 'src/labstep-web/scss/libs/_vars.scss';

:global {
    :local(.container) {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        overflow-y: auto;
        overflow-x: hidden;

        .ui.dropdown {
            width: 100% !important;
        }

        .ui.dropdown .ui.blue.button {
            width: 100% !important;
        }
    }
}


:local(.scrollableContent) {
    flex: 1 1;
    overflow-y: auto;
}

:local(.barrier) {
    border-bottom: 2px solid $colorArctic;
    margin-top: 20px;
    margin-bottom: 20px;
}