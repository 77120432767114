@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.true) {
  color: $colorPunchDrunk;
}

:local(.false) {
  color: $colorSilverSurfer;
}

:local(.new) {
  color: $colorYellow;
}

:local(.approved) {
  color: $colorPurple;
}

:local(.ordered) {
  color: $colorOrange;
}

:local(.back_ordered) {
  color: $colorBlue;
}

:local(.partially_received) {
  color: $colorViolet;
}

:local(.received) {
  color: $colorGreen;
}

:local(.cancelled) {
  color: $colorRed;
}

:local(.status) {
  >*:first-child {
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: $p-1;
    overflow: hidden;
  }

  >i {
    flex-shrink: 0;
  }

  >i::before {
    top: -20%;
    position: relative;
  }
}

:local(.showLog) {
  line-height: normal;
  margin-top: -8px;
  padding-bottom: 8px;
  font-size: 80%;
}