@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.searchBar) {
  flex-grow: 1;

  >div {
    height: 100%;
  }

  >*:nth-child(2) {
    margin: 0 0 0 5px !important;
  }

  display: flex;

  @include mobile {
    flex-direction: column;

    >*:nth-child(2) {
      margin-left: 0px !important;
    }
  }
}

:local(.searchBarContainer) {
  display: flex;
}

:local(.searchBarAction) {
  border: 1px solid $colorArctic !important;
  box-shadow: none !important;
  color: $colorLabstepBlue !important;
  background-color: white !important;
  border-radius: 0px !important;
  margin-right: 0px !important;

  @include mobile {
    border-radius: 5px !important;
    justify-content: center !important;
  }

  &:hover {
    color: $colorBlue !important;
  }
}

:local(.searchBarActionContainer) {
  display: flex;
  padding: 0px !important;
}