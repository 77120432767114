@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.item) {

  >img:first-child,
  div:first-child {
    flex-shrink: 0;
  }

  composes: item from '../../../Protocol/List/styles.module.scss';
}

:local(.list) {
  composes: list from '../../../Protocol/List/styles.module.scss';
}

:local(.message) {
  flex-grow: 1;
  margin-left: 15px;
  padding: 10px 5px;
}

:local(.active) {
  background-color: $colorSailBoat;
}

:local(.selectable) {
  cursor: pointer;
}