@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.hr) {
    border: 0.5px solid #DBE0E8;
    margin: 0;
}

:local(.content) {
    padding: 10px;
}

:local(.footer) {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

:local(.retry) {
    border: none !important;
    color: $colorLabstepBlue !important;
    background: none !important;
    margin-right: 30px !important;
}

:local(.cancel) {
    border: none !important;
    color: $colorCrimsonRed !important;
    background: none !important;
    margin-right: 40px !important;
}

:local(.done) {
    margin-right: 0 !important;
}

:local(.noBackGround) {
    background-color: $colorBrightWhite !important;
}