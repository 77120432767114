@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.floatingSearchContainer) {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  >button {
    margin-right: 0px !important;
  }
}

:local(.dropdownContainer) {
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1000;
  width: 270px;
  background-color: #fff;

  &.alignRight {
    left: auto;
    right: 0;
  }
}

:local(.groupHeading) {
  padding: 0px 12px;
}

:local(.group) {
  padding: 0px !important;
  border-bottom: 1px solid $colorArctic;
  margin-bottom: 4px;
}