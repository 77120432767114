@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.preview) {
    width: 534px;
    height: 72px;
    background-color: $colorLightGrey;
    margin-bottom: 10px;
}

:local(.name) {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
}

:local(.relation) {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: $colorEarlGray;
}

:local(.subname) {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
}