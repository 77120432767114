@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.actionCreate) {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

:local(.barrier) {
    border-bottom: 2px solid $colorArctic;
    margin-top: 20px;
    margin-bottom: 20px;
}

:local(.addInventoryField) {
    flex: 1 1 calc(37% - 8px);
    min-width: 200px;
}

:local(.amountAupdate) {
    flex: 1 1 calc(63% - 8px);
    min-width: 200px;
}