@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.item) {}

:local(.list) {
  line-height: 2;
  margin-top: 0px !important;
}

:local(.selected) {
  font-weight: 500;
  color: $colorLabstepBlue;
}