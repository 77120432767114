@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.progress) {
    position: absolute;
    z-index: 10000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 400px;
    height: 100px;
    background: $colorBrightWhite;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: grab;
}