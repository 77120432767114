@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.image) {
    border-radius: 8px 0 0 8px;
    background: #2089C9;
    height: 100%;
}

:local(.title) {
    color: $colorLightBlack;
    font-weight: 600;
}

:local(.content) {
    flex: 1;
    width: 241px;
    padding: 10px 10px 10px 20px;
}

:local(.progressContainer) {
    width: 100%;
    background: $colorArctic;
    border-radius: 5px;
    overflow: hidden;
    margin: 5px 0;

}

:local(.progressBar) {
    min-width: 75px;
    height: 25px;
    background: $colorWimbledon;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-radius: 5px;
}

:local(.progressText) {
    padding-left: 20px;
    color: $colorBrightWhite;
    font-weight: 700;
}

:local(.failedRequests) {
    display: flex;
    justify-content: space-between;
}

:local(.errorsCount) {
    color: $colorRed !important;
    background: none !important;
}

:local(.button) {
    background: none !important;
}