@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.subHeader) {
  color: $colorRiverBed;
}

:local(.container) {
  color: $colorRiverBed;
  font-weight: 500;
  display: flex;
  align-items: center;

  >div:first-child {
    cursor: pointer;
    padding-top: 4px;

    &:hover {
      color: $colorLabstepBlue;
    }
  }
}

:local(.right) {
  display: flex;
  align-items: center;

  >i {
    margin: 5px 5px 0 5px !important;
  }

  >*:last-child {
    padding-top: 4px;
  }
}

:local(.mobile) {
  display: flex;
  align-items: center;

  >i {
    margin: 0px 5px;
  }

  >*:nth-child(2) {
    padding-top: 5px;
  }
}